/*Responsiveness*/
@media (max-width : 1180px) {
    .MenuRespons{
      display: flex;
      position: relative;
    }
    .MenuShow{
      display: flex;
    }
    .navbarIcons{
      display: none;
    }  
    .footer,
    .bottom{
      width: 94%;
    }
    .login{ 
      flex-wrap: wrap;
      justify-content: center;
    }
    .login .loginLogo{
      height: 70%;
      width: 60%;
    }
    .login .loginForm,
    .loginForm .loginDetails{
      height: auto;
      width: 70%;
    }
    .loginDetailsDivIII .Country_code{
      width: 18%;
    }
    .loginDetailsDivIII .Phone_number_for_otp{
      width: 79%;
    }
    .slick-slide>div{
      margin: 2vw 10vw;
      width: 22vw;
    }
  }