@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&family=Saira:ital,wght@0,100..900;1,100..900&family=Space+Grotesk:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Sarala:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&family=Space+Grotesk:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&family=Saira:ital,wght@0,100..900;1,100..900&family=Space+Grotesk:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Saira:ital,wght@0,100..900;1,100..900&family=Space+Grotesk:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&family=Saira:ital,wght@0,100..900;1,100..900&family=Space+Grotesk:wght@300..700&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

*{
    margin: 0vw;
    padding: 0%;
    box-sizing: border-box;
    text-decoration: none;
    border: none;
    outline: none;
    scroll-behavior:smooth;
    font-family: 'Lexend Deca',"Work Sans",'Pacifico', 'Plus Jakarta Sans','cursive','Poppins', sans-serif,'Space Grotesk','Saira','Montserrat'; 
}

.header{
    width: 1440px;
    height: 105px;
}

html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a {
    text-decoration: none;
    color: black;
}
button{
  cursor: pointer;
}

/*Navbar*/
.Navbar{
  height : 6vw;
}
.MenuRespons{
  display: none;
  font-size: 2.5vw;
}

/*Search bar */
.search-bar{
  border:none;
  outline:none;
  width: 16vw;
  height: 1.4vw;
  outline: none;
  box-sizing: border-box;
  padding: 0.5vw 0.7vw;
  font-size: 0.9vw;
  margin-top: 0.1vw;
  line-height: 1.4vw;
}
.options{
  display: flex;
  margin-top: 0.7vw;
  margin-right: 1.3vw;
  justify-content: space-evenly;
}
.navLinks{
  margin-top:1vw;
  font-weight: 700;
  font-size: 1.2vw;
  width: 30vw;
  height: 1.7vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dropdown {
  position: absolute;
  top: calc(80%);
  margin-left: 1.7vw;
  width: 15vw;
  background-color: #fff;
  /* border: 1px solid #ccc; */
  border-radius: 0.4vw;
}
.dropdown ul {
  list-style-type: none;
  padding: 0vw;
  font-size: 1vw;
  margin: 0vw;
}
.dropdown ul li {
  padding: 0.8vw;
}
.dropdown ul li:hover {
  background-color: #e0d7d7;
}
.MenuDropdown{
  position: absolute;
  top: calc(100%);
  margin-left: 1vw;
  box-shadow: 0.3vw 2.7vw 2vw 0.8vw rgba(0, 0, 0, 0.5);
  border-radius: 0vw 0vw 2vw 2vw;
  display: none;
  flex-direction: column;
  gap: 50px;
  width: 98%;
  justify-content: center;
  height: auto;
  left: 0;
  font-weight: 600;
  background-color: white;
  
  padding-top: 1.2vw;
  padding-bottom: 350px;
}
.MenuDropdown .MenuDropdownOpt{
  line-height: 4vw;
  width: 100%;
  text-align: center;
}
.MenuDropdown .MenuDropdownOpt:hover{
  background-color: rgba(206, 191, 224, 0.5);
}
.MenuShow{
  display: flex;
}

/*Home Find Car*/
.Home{
  width: 100%;
  height: 40vw;
  position: relative;
}
.Home .homeI{
  /* background-image: url('../public/Images/Untitled1.jpg'); */
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 5vw;
  padding-left: 3%;
}
.Home .homeFindCar{
    width: 25.1vw;
    height: 30vw;
    font-weight: 900;
    border-radius: 0.7vw;
    background-color: rgba(255, 255, 255, 1);
    font-family: 'Lexend Deca';
}
.homeFindCar .FindCarHeading h1{
  font-size: 2vw;
  line-height: 2.8vw;
  width: 80%;
  margin: auto;
  color: rgba(0, 0, 0, 1);
  text-align: center;
  margin-bottom: 1.0vw; 
  padding-top: 1vw;
}
.homeFindCar .FindCarType{
  height: 2.7vw;
  font-size: 1.3vw;
  line-height: 1.4vw;
  text-align: center;
  margin-bottom: 1.3vw;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.FindCarType .CarTypeNew,
.FindCarType .CarTypeUsed{
  height: 100%;
  width: 8vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  color: #FFFFFF;
}
.FindCarType .CarTypeUsed{
  background-color: #FFFFFF;
  color: #000000;
  border: 0.17vw solid #000000;
}

/*HomeII*/
.HomeII{
  width: 100%;
  height: 43.5vw;
}
.HomeII .homeII{
  height: 100%;
}
.homeII .homeIIHeading{
  width: 35vw;
  margin: -1vw auto;
  height: 3.7vw;
  text-align: center;
}
.homeIIHeading h1{
  align-content: center;
  font-weight: 700 ;
  font-family: 'Lexend Deca';
  color: white;
  line-height: 3.7vw;
  font-size: 3vw;
}
.homeII .brands{
  width: 80%;
  height : 8vw;
  margin: 3.6vw auto;
  display: flex;
  justify-content: space-between;
}
.brands .brandsName{
  background-color: white;
  border-radius: 0.6vw;
  width: 8vw;
  height : 8vw;
}
.brands .brandsWithFlex{
  display: flex;
  justify-content: center;
  align-items: center
}
.brandsName .brandsImage{
  width: 100%;
  height: 100%;
}

/*HomeIII*/
.HomeIII,
.AboutUs{
  text-align: center;
  width: 70vw;
  height: 40%;
  display:flex;
  flex-direction:row;
  justify-content: space-between;
  margin: 1.5% auto
}
.HomeIII .card,
.AboutUs .cardAboutUs{
  width: 21vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.HomeIII .cardIcon,
.AboutUs .cardIconAboutUs{
  background-color: #000000;
  width: 4.3vw;
  height:4.3vw;
  color:white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.HomeIII .cardHeading,
.AboutUs .cardHeadingAboutUs{
  width: auto;
  height: 2vw;
}
.HomeIII .cardHeading h6,
.AboutUs .cardHeadingAboutUs h6{
  height:100%;
  width:100%;
  font-weight: 600;
  font-size: 1.2vw;
  font-family: 'Lexend Deca';
  line-height: 2vw;
  color: #03040A;
}
.HomeIII .cardDesc,
.AboutUs .cardDescAboutUs{
  width: auto;
  height: 6vw;
}
.HomeIII .cardDesc p,
.AboutUs .cardDescAboutUs p{
  font-family: 'Space Grotesk';
  font-weight: 400;
  font-size: 1vw;
  line-height: 2.1vw;
  color: #7F808E;
}
.HomeIII .cardIcon .cardIconLogo,
.AboutUs .cardIconAboutUs .cardIconLogoAboutUs{
  color: white;
  width: 2vw;
  height: 2vw;
}

.homeFindCar .FindCarSelectBtn{
  display: flex;
  justify-content: center;
  align-items: center;
}
.homeFindCar button{
  width: 100%;
  max-width: 18vw;
  height: 2.2vw;
  background-color: #000000;
  color: #FFFFFF;
  font-size: 1.3vw;
  font-weight: 700;
  line-height: 2.2vw;
}

/*updates*/
.contactFormInitial{
  width: 100%;
  height: 2.7vw;
  font-size: 1.1vw;
}
.input-field-initial{
  width: 76%;
  height: 2.7vw;
  margin: auto auto 0.7vw;
  border: 1px #00000080 solid;
  color: #7F808E;
  font-family: 'Space Grotesk';
  font-size: 1.1vw;
  font-weight: 400;
}
.input-field-initial input{
  text-indent: 0.8vw;
}
.input-field-initial input,select{
  width: 100%;
  height: 90%;
  font-size: 1.1vw;
  color: #7F808E;
}
.contact-form-inital .buttonContactInitial{
  width: 40%;
  text-align: center;
  font-size: 1.1vw;
  height: 2.3vw;
  margin: 1.2vw auto;
}
.buttonContactInitial .btnContactInitial{
  width: 100%;
  height: 100%;
  font-family: 'Lexend Deca';
  font-size: 1.1vw;
  font-weight: 700;
  border-radius: 0.84vw;
}



.iconContainerStyle {
    width: 40px;
    height :40px;
    border: 1px solid #C3D4E966;
    border-radius: 90%;
    margin-top: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
  };
  
.iconStyle{
    color: #596780;
    font-size: "20px"; 
}
.asterisk{
  color: #FF4423;
}

/*Contact Form*/

#contact-form-container{
  position: relative;
  transition: .3s ease;
  pointer-events: auto;
}

#contact-form-container .active{
  filter: blur(1.2vw);
  pointer-events: none;
}

.contact-form{
  width: 62%;
  height: 22vw;
  margin: 2.5vw auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
#contact-form-container .popup{
  position: absolute;
  width: 65%;
  height: 65%;
  filter: blur(0px);
  background-color: white;
  box-shadow: 0.1vw 0.1vw 0.5vw 0.5vw rgba(0, 0, 0, 0.5);
  border-radius: 0.7vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%) scale(.8);
  opacity: 0;
  pointer-events: none;
  transition: .3s ease;
}
#contact-form-container .popup.active{
  opacity: 1;
  pointer-events: auto;
  transform: translate(-50%,-50%) scale(1);
}
.popup .popupCloseDiv{
  display: flex;
  justify-content: flex-end;
}
.popup .popupCloseDiv button{
  background-color: transparent;
  height: 2.1vw;
  width: 2.1vw;
}
.popup .popupCloseDiv .popupCloseDivIcon{
  background-color: white;
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

.contact-form .input-box{
  width: 100%;
  height: 7vw;
  display: flex;
  justify-content: space-between;
}
.contact-form .input-box .input-field{
  width: 18.5vw;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.contact-form .input-box .input-field input,
.contact-form .input-box .input-field label{
  width: 100%;
}
.contact-form .input-box .input-field label{
  font-family: 'Lexend Deca';
  color: #FFFFFF;
  font-weight: 600;
  font-size: 1.2vw;
  line-height: 2vw;
  height: 1.7vw;
  width: 100%;
  margin-bottom: -10px; /*keep the px*/
}
.contact-form .input-box .input-field input{
  font-family: 'Space Grotesk';
  height: 3vw;
  width: 100%;
  margin-top: -0.3vw;
  padding: 1vw 1.5vw 1vw 1.5vw;
  gap: 1vw;
  text-indent: 1vw;
  font-size: 0.9vw;
}

.buttonContact{
  min-width: 10.5vw;
  min-height: 3.3vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.buttonContact button{
  font-size: 0.9vw;
  border-radius: 0.6vw;
  padding: 0vw 2vw;
  background-color: #000000;
  color: #FFFFFF;
  width: 100%;
  height: 100%;
  font-family: 'Lexend Deca';
  font-weight: 700;
  text-align: center;
}


/*footer*/
.footerMain{
  background-color: #03040A;
  width: 100%;
  height: 31vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer{
  display: flex;
  justify-content: space-between;
  padding-top: 4vw;
  width: 75%;
}
.footerOne{
  width: 25vw;
  height: 14vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.footerOne .contactSocial {
  width:55%;
  height: 2.7vw;
  display: flex;
  justify-content: space-between;
}
.footerOne .contactSocial .social{
  background-color: #D43500;
  height: 2.5vw;
  width: 2.5vw;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center; 
}
.footer .contactSocial .social:hover{
  transform: translateY(-0.2vw);
  box-shadow: 0vw 0.3vw 0.8vw rgba(76, 75, 75, 0.4);
}
.footerOne .contactSocial .social a{
    color: white;
    font-size: 1.5vw;
}
.footerTwo{
  width: 18vw;
  height: 14vw;
  gap : 2.5vw;
  display: flex;
  justify-content: space-between;
}
.footerTwo-1{
  width: auto;
  height: 100%; 
}
.footerTwo-1 h6{
  width: 100%;
  height: 2.5vw;
  color: #FFFFFF;
  font-family : 'Lexend Deca';
  font-weight : 600;
  font-size : 1.3vw;
  line-height : 2.5vw;
}
.footerTwo-1 .links{
  width: 100%;
  font-family : 'Space Grotesk';
  font-weight: 400;
  font-size: 1vw;
  line-height : 1.7vw;
  color : #E5E6E8;
  display: block;
  justify-content: space-around;
  margin-top: 0.6vw;
}
.footerThree{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  max-width: 20vw;
}
.footerThree .subscribe{
  display: flex;
  flex-direction: column;
  width: 20vw;
  height: auto;
  gap : 1.4vw;
}
.footerThree .startUpLogo{
  display: flex;
  flex-direction: column;
  width: 20vw;
}
.startUpLogo{
  padding-top: 2vw;
  height: 7.2vw;
}
.startUpLogoImg {
  display: flex;
  justify-content: space-between;
}
.startUpLogo .imageLogoStartup{
  width: 9vw;
  height: 100%;
}
.bottom{
  margin-top: 3.3vw;
  width: 75%;
}
.bottom p{
  width: auto;
  height: 100%;
  line-height: 1vw;
}

/* Black bg headings*/
 .BlackBg{
  padding: 1.2vw;
  background-color: #292929;
  width: 100%;
  background-repeat: repeat;
  background-image : url('./Components/Images/bgEffect.png');
  background-size: 39vw auto;
  background-blend-mode: overlay;
 }
.HeadingBoxBlack{
  width: 46vw;
  height: 9vw;
  margin: auto;
  color: #ffffff;
}
.HeadingBoxBlack .HeadingBlackDiv{
  width: 46vw;
  height: 9vw;
  margin: 2.3vw auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.HeadingBlackDiv .BlackDivfirst,
.HeadingWhiteDiv .WhiteDivfirst{
  margin-top: 0vw;
  display: flex;
  align-items: center;
}
.HeadingBlackDiv .BlackDivfirst hr{
  width: 1.9vw;
  border-top: 0.2vw solid;
  margin: auto 0.9vw;
}
.HeadingBlackDiv .BlackDivfirst p{
  font-size: 1vw;
  font-weight: 500;
}
.HeadingBlackDiv h1,
.HeadingWhiteDiv h1{
  font-family: 'Lexend Deca';
  font-weight: 600;
  font-size: 3vw;
  line-height: 4.1vw;
  gap: 1vw;
}
.HeadingBlackDiv .BlackDivLast{
  font-family: "Space Grotesk";
  font-weight: 700;
  color : #FFFFFF;
  line-height: 100%;
  font-size: 1.3vw;
  height: 1.5vw;
  margin-top: 1.3vw;
}

/* White bg headings*/
.WhiteBg{
  background-color: #F6F7F9;
  width: 100%;
  /* padding: 3.5vw; */
  font-family: "Space Grotesk";
}
.WhiteBgContent{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  padding-top: 1vw;
}
.WhiteBg .headingBoxWhite{
  margin: auto;
}
.WhiteBg .HeadingWhiteDiv{
  width: auto;
  height: 9.5vw;
  margin: 3vw auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 500;
  font-size: 1.1vw;
}
.HeadingWhiteDiv .WhiteDivfirst hr{
  width: 1.9vw;
  border-top: 0.2vw solid black;
  margin: auto 0.9vw;
}
.HeadingWhiteDiv .WhiteDivLast{
  font-weight: 700;
  color : #7F808E;
  line-height: 1.5vw;
  font-size: 1.3vw;
  height: 2vw;
  margin-top: 1.1vw;
}
.HeadingWhiteDiv .WhiteDivMiddle,
.HeadingBlackDiv .BlackDivMiddle{
  margin-top : 1vw;
  font-family: 'Lexend Deca';
}

/* button for more*/

.WhiteHr{
  width: 48vw;
  background-color: white;
  margin: 2.5vw auto; 
  height: 0.2vw;
}

.btnForMoreDiv{
  width: 9vw;
  height: 2.3vw;
  margin:auto;
  text-align: center; 
  display: flex; /* Added for vertical alignment */
  align-items: center;
}
.btnForMore{
  font-family: 'Lexend Deca';
  /* line-height: 1.5vw; */
  letter-spacing: 0em;
  font-size: 1vw;
  font-weight: 400;
  background-color: white;
  width: 100%;
  height: 100%;
  color: black;
  border-radius: 0.7vw;
  cursor: pointer;
}

/* DivContent */
.DivContent{
  width: 87%;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 1.5vw;
  line-height: 2.3vw;
  color: #FFFFFF;
  margin: 3vw auto 2vw auto;
  text-align: center;
}

.getInTouch{
  width: 100%;
  height: 50vw;
  background-image: linear-gradient(to right, #FFFFFF 75%, #000000 25%);
  box-shadow: 0vw 141px 200px -80px #193A4B4D;  /*No responsiveness*/
  font-family: 'Montserrat';
}

.getInTouch .getInTouchDiv{
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 100%;
  pointer-events: auto;
  transition: .3s ease;
}

.getInTouch .getInTouchForm{
  width: 40%;
  height: 80%;
  /* position: absolute; */
  /* top: 15%;
  left: 10%; */
  padding-top: 2.5vw;
  padding-left: 2vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.getInTouchForm .TouchFormHeading{
  width: 100%;
  height: 6.5vw;
  gap: 1.5vw;
  font-weight: 600;
  font-size: 1vw;
  line-height: 1.7vw;
  color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.getInTouchForm .getInTouchFormDetails{
  width: 100%;
  display: flex;
  flex-direction: column;
}
.getInTouchFormDetails input,
.getInTouchFormDetails button,
.getInTouchFormDetails select{
  width: 100%;
  height: 3.4vw;
  margin: 0.7vw 1.1vw 0.8vw 0vw;
  
}
.getInTouchFormDetails input,
.getInTouchFormDetails select{
  border: 0.09vw solid #E0E0E0;
  font-size: 1vw;
  padding-left: 1.2vw;
}
.getInTouchFormDetails button{
  background-color: #000000;
  color: #FFFFFF;
  font-weight: 600;
  font-size: 1.1vw;  
}

.getInTouchForm .getInTouchFormContact{
  width: 100%;
  height: 8%;
  display: flex;
  justify-content: space-between;
}
.getInTouchFormContact .FormContactPhone {
  display: flex;
  align-items: center;
}
.FormContactPhone .contactIcon {
  margin-right: 0.8vw;
  height: 100%;
  width: 2vw;
  font-size: 2vw;
}
.contactPhoneheadings {
  flex-grow: 1;
}
.contactPhoneheadings p {
  margin: 0;
  font-weight: 600;
  font-size: 1.1vw;
  line-height: 1.5vw;
}
.getInTouch .getInTouchPic{
  height: 95%;
  width: 35%;
  background-image: url('./Components/Images/getInTouch.png');
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 2.5vw;
  margin-right: -5vw;
}

/*Get In Touch Popup*/
.getInTouchDiv.active{
  filter: blur(1vw);
  pointer-events: none;
}

#getInTouchId{
  position: relative;
}

#getInTouchId .popup2{
  position: absolute;
  width: 65%;
  height: 65%;
  filter: blur(0vw);
  background-color: white;
  box-shadow: 0.1vw 0.1vw 0.5vw 0.5vw rgba(0, 0, 0, 0.5);
  border-radius: 0.7vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%) scale(.8);
  opacity: 0;
  pointer-events: none;
  transition: .3s ease;
}
#getInTouchId .popup2.active{
  opacity: 1;
  pointer-events: auto;
  transform: translate(-50%,-50%) scale(1);
}

.popup2 .popupCloseDiv2{
  display: flex;
  justify-content: flex-end;
  padding-bottom: 3.5vw;
}
.popup2 .popupCloseDiv2 button{
  margin-bottom: -4vw;
  background-color: transparent;
  height: 2.3vw;
  width: 2.3vw;
}
.popup2 .popupCloseDiv2 .popupCloseDivIcon2{
  background-color: white;
  width: 100%;
  height: 100%;
  border-radius: 100%;
}


/*TermsCondII*/
.IIPart{
  width: 100%;
  height: auto;
  margin-bottom: 6vw;
}
.IIPartHeading{
  font-family: 'Lexend Deca';
  font-weight: 500;
  font-size: 3.4vw;
  line-height: 4vw;
  width: auto;
  height: 4vw;
  margin: 3.9vw auto 4vw;
  display: flex;
  justify-content: center;
}
.IIPartSubHeading h5{
  width: auto;
  height: 2vw;
  font-family: 'Space Grotesk';
  font-weight: 700;
  font-size: 1.7vw;
  line-height: 2vw;
  margin-top: 1.7vw;
  margin-left: 2.3vw;
  color: #000000;
}
.IIPartSubHeading p{
  width: 95%;
  height: auto;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 1.3vw;
  line-height: 2vw;
  margin-top: 1.7vw;
  margin-left: 2.3vw;
  color: #000000B2;
}
.IIPartSubHeading ol,
.IIPartSubHeading ul{
  margin-left: 1.4vw;
}
.IIPartSubHeading li{
  margin: 1.4vw auto 1.7vw;
}

/*TEAM*/
.TeamCoordinators{
  width: 80%;
  height: 49%;
  margin: 8vw auto 2.7vw;
  display: flex;
  justify-content: space-between;
}
.TeamCoordinators .team-mates{
  height: 100%;
  width: 23%;
  background-color: #F5F5F5;
  position: relative;
}
.team-mates .team-mates-bg{
  width: 90%;
  height: 70%;
  margin: 7.3% auto;
  background-color: #292929;
  background-repeat: repeat;
  background-image : url('./Components/Images/bgEffect.png');
  background-size: 40vw auto;
  background-blend-mode: overlay;
}
.team-mates-bg .team-mates-img{
  width: 90%;
  height: 100%;
  position: absolute;
  top: -25%; /*Move image 25% up from its original position */
}

.team-mates .team-mates-names{
  width: 100%;
  height: auto;
  color: #000000;
  text-align: center;
  font-family: 'Poppins';
  margin-top: -0.6vw;
}
.team-mates-names h4{
  line-height: 2.8vw;
  font-weight: 700;
  font-size: 1.7vw;
}
.team-mates-names p{
  line-height: 1.7vw;
  font-weight: 400;
  font-size: 1.2vw;
}
.buttonContactTeam{
  width: 9.5vw;
  height: 3.3vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.buttonContactTeam button{
  font-size: 0.9vw;
  border-radius: 0.6vw;
  padding: 0vw 2vw;
  background-color: #000000;
  color: #FFFFFF;
  width: 100%;
  height: 100%;
  font-family: 'Lexend Deca';
  font-weight: 700;
  text-align: center;
}

/*App Lite*/
.AppLite{
  display: flex;
  background-color: rgba(245, 245, 245, 1);
}
.WhiteBg .appLite{
  padding-top: 3%;
  width: 55%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.WhiteBg .mobileApp{
  width: 43%;
  height: 100%;
  background-position: center;
  background-image: url('./Components/Images/AppLite.png');
  background-repeat: no-repeat;
  position: relative;
  background-size: 90%;
}
.appLite .buttonContactApp{
  min-width: 10.5vw;
  min-height: 3.3vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -1.3vw auto 2.5vw;
}
.buttonContactApp button{
  background-color: #000000;
  color: #FFFFFF;
  width: 100%;
  height: 100%;
  font-family: 'Lexend Deca';
  font-weight: 700;
  font-size: 0.9vw;
  text-align: center;
  border-radius: 1vw;
  padding: 0vw 2vw;
  border-radius : 0;
}
.appLite .para{
  font-weight: 400;
  font-family: 'Space Grotesk';
  font-size: 1vw;
  width: 43vw;
  text-align: center;
}
.appLite h6{
  text-align: center;
  font-family: 'Lexend Deca';
  font-weight: 500;
  font-size: 0.99vw;
  line-height: 1vw;
  margin-top: 1vw;
}
.appLite .playStore{
  height: 3.2vw;
  width: 20vw;
  display: flex;
  justify-content: space-between;
}
.appLite .playStore .playStoreBtnI,
.appLite .playStore .playStoreBtnII{
  width: 8.7vw;
  height: 100%;
  display: flex; 
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.appLite .playStore .playStoreBtnI{
  background-image: url('./Components/Images/GooglePlayBadge.jpg'); /*Done*/
}
.appLite .playStore .playStoreBtnII{
  background-image: url('./Components/Images/AppStoreBadge.jpg');  /*Done*/
}

/*Login*/
.login{
  width: 100%;
  height: auto;
  display: flex;
}
.login .loginLogo{
  height: 52vh;
  margin-top: 6vw;
  margin-left: 4vw;
  width: 50%;
}
.login .loginForm{
  height: auto;
  width: 50%;
  margin-top: 3.5vw;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.loginForm .loginHeading h1{
  font-family: 'Lexend Deca';
  font-size: 2vw;
  line-height: 2.7vw;
}
.loginForm .loginDetails{
  margin-top: 2vw;
  width: 36vw;
  gap: 1.3vw;
  padding: 1vw; 
  height: 40vh;
  text-align: center;
  background-color: #2D2E2F;
  border-radius: 2vw;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.loginDetails .loginDetailsDivI,
.loginDetails .loginDetailsDivII,
.loginDetails .loginDetailsDivIII,
.loginDetails .loginDetailsDivIV{
  width: 95%;
  font-family: 'Plus Jakarta Sans';
  text-align: center;
}
.loginDetails .loginDetailsDivI{
  font-weight: 600;
  font-size: 1.3vw;
  height: 2vw;
  line-height: 2.2vw;
  color: #FFFFFF;
}
.loginDetails .loginDetailsDivII{
  background-color: #FFFFFF;
  border-radius: 0.5vw;
  padding: 0.5vw 1vw;
  gap: 0.5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.1vw;
  border: 1px solid rgba(255, 255, 255, 0.1);
}
.loginDetailsDivII p{
  font-weight: 600;
  font-size: 0.9vw;
  line-height: 1.1vw;
  text-align: center;
}
.loginDetails .loginDetailsDivIV{
  background-color: #FF654D;
  border-radius: 0.7vw;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 1.1vw 0.7vw; */
  height: 3.1vw;
}
.loginDetailsDivIV button{
  display: flex;
  background-color: transparent;
  font-size: 1.1vw;
  color: #FFFFFF;
  font-weight: 700;
  cursor: pointer;
}
.loginDetails .loginDetailsDivIII{
  height: 3.1vw;
  display: flex;
  justify-content: space-between;
}
.loginDetailsDivIII .Country_code,
.loginDetailsDivIII .Phone_number_for_otp,
.loginDetailsDivIII .otp_for_verification{
  background-color: transparent;
  color: #FFFFFF;
  border: 0.1vw solid rgba(255, 255, 255, 0.2);
  border-radius: 0.7vw;
  font-weight: 500;
  font-size: 1vw;
}
.loginDetailsDivIII .Country_code{
  width: 12%;
  height: 100%;
  padding: 0vw;
  text-align: center;
}
.loginDetailsDivIII .Phone_number_for_otp{
  width: 85%;
  height: 100%;
  padding: 0.8vw 1vw;
  padding-left: 1vw;
}
.Phone_number_for_otp::-webkit-inner-spin-button,
.Phone_number_for_otp::-webkit-outer-spin-button,
.otp_for_verification::-webkit-inner-spin-button,
.otp_for_verification::-webkit-outer-spin-button{
  -webkit-appearance: none;
  margin: 0;
}
.loginDetailsDivIII .otp_for_verification{
  width: 100%;
  height: 100%;
  padding: 0.8vw 1vw;
  padding-left: 1vw;
}
.loginDetailsDivI button{
  font-weight: 600;
  font-size: 0.9vw;
  line-height: normal;
  color: rgba(254, 135, 35, 1);
  text-align: center;
  background-color: transparent;
  cursor: pointer;
}

/*.testimonialCarousel*/
.testimonial{
  padding: 0vw 0vw 2vw 0vw;
  position: relative;
  margin: auto;
  width: 85%;
  height: 40vw;
  font-family: "Work Sans";
}
/* .testimonial-box{
  margin: auto;
  padding: 0vw 0.8vw;
  width: 97%;
} */
.testimonials{
  height: 22.5vw;
  width: 22vw;
  margin: 0 auto 1.3vw auto;
  box-shadow: 0vw 0.3vw 1.2vw 0vw #6E82D02B;
  background: #FFFFFF01;
  border-radius: 0.5vw;
  position: relative;
}
.slick-slide>div{
  margin: 2vw 2.5vw;
  width: 22vw;
}
/* .testimonial_slider{
  width: 100%;
  margin: 0 auto;
  position: relative;
} */
.testimonials .testimonialHeader{
  margin: 2vw auto 1vw;
  width: 88%;
  padding-top: 0.7vw;
  height: auto;
  display: flex;
  align-items: center;
}
.testimonialHeader .customerImage {
  height: 5.3vw;
  width: 5.3vw;
  margin-right: 0.6vw;
}
.customerImage img{
  box-shadow: 0vw 0.4vw 0.7vw 0.4vw #D3DCE8;
  border-radius: 50%;
  border: 0.3vw solid #FFFFFF;
  width: 5.2vw;
  height: 5.2vw; 
}
.customerNameReview {
  flex-grow: 1;
}
.customerNameReview p {
  margin: 0;
  font-size: 1.3vw;
  font-weight: 800;
  line-height: 2.1vw;
  color: #071537;
  height: 1.8vw;
  width: auto;
  font-weight: 600;
}
.testimonials .testimonailReview{
  margin: auto;
  width: 94%;
  height: auto;
  font-weight: 500;
  font-size: 0.95vw;
  padding: 0.8vw;
  text-align: left;
  line-height: 1.7vw;
}
.blackCircle {
  position: absolute;
  top: -2vw;
  right: -10%;
  width: 4.7vw;
  height: 4.7vw;
  background-color: black;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  font-size: 1.6vw; 
  justify-content: center;
} 

/*How It Works*/
.howItWorks{
  text-align: center;
  width: 55vw;
  max-height: 54vw;
  display:flex;
  flex-direction:row;
  justify-content: space-between;
  margin: 3vw auto 4vw;
}
.howItWorks .card{
  width: 40vw;
  height: 14vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.howItWorks .cardIcon{
  background-color: #000000;
  width: 4.3vw;
  height:4.3vw;
  color:white;
  display: flex;
  justify-content: center;
  align-items: center 
}
.howItWorks .cardHeading{
  width: auto;
  height: 2vw;
}
.howItWorks .cardHeading h6{
  height:100%;
  width:100%;
  font-weight: 600;
  font-size: 1.2vw;
  font-family: 'Lexend Deca';
  line-height: 2vw;
  color: #03040A;
}
.howItWorks .cardDesc{
  width: auto;
  height: 6vw;
}
.howItWorks .cardDesc p{
  font-family: 'Space Grotesk';
  font-weight: 400;
  font-size: 1vw;
  line-height: 2.5vw;
  color: #7F808E;
}
.howItWorksContent{
  color : #000000B2;
  font-family : 'Poppins';
  font-weight : 600;
  font-size : 1.3vw;
  line-height : 2.7vw;
  width : 93%;
  text-align: center;
  margin-bottom: 4vw;
}
.howItWorks .cardIcon .cardIconLogo{
  color: white;
  width: 2vw;
  height: 2vw;
}

/*Services */
/*ServicesI*/
.servicesI{
  width: 100%;
  height: 35vw;
  padding-top: 6vw;
  background-image: linear-gradient(to top, #C7C1C0, #F6F7F9);
  display: flex;
  font-family: 'Lexend Deca';
}
.servicesI .servicesIImage{
  height: 82%;
  margin-left: 4vw;
  width: 40%;
}
.servicesI .servicesIContent{
  width: 48%;
  height: 80%;
  padding-left: 5%;
  padding-top: 1%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.servicesIContent .servicesIContentHeading{
  height: 53%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.servicesIContentHeading h1{
  font-size: 3vw;
  font-weight: 700;
}
.servicesIContentHeading p{
  font-size: 1.3vw;
  font-weight: 400;
  padding-top: 1vw;
}
.servicesIContent .servicesIContentIcons{
  display: flex;
  justify-content: space-between;
  height: auto;
  width: 90%;
}
.servicesIContentIcons .servIContentIcon{
  height: 8vw;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.servIContentIcon .servIIconDiv{
  width: 3.1vw;
  height: 3.1vw;
  background-color: #c5c3c3;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.servIContentIcon .servIIconHeading h2{
  font-weight: 700;
  font-size: 1.6vw;
  align-items: center;
}
.servIContentIcon .servIIconPara p{
  font-weight: 400;
  font-size: 1vw;
}

/*ServicesII*/
.servicesII{
  width: 100%;
  height: 39vw;
  color: #FFFFFF;
}
.servicesII .servicesIIContent{
  width: 86%;
  height: 19vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 4vw auto 1.5vw;
}
.servIIcon{
  width: 23%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
}
.servIIcon .servIIDivIcon{
  width: 8.7vw;
  height: 9vw;
  background-color: #FFFFFF;
  border-radius: 0.7vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.servIIcon .servIIDivHeading{
  font-weight: 700;
  font-size: 1vw;
  line-height: 3.5vw;
  /* text-align: center;  */
}
.servIIcon .servIIDivContent{
  font-family: 'Space Grotesk';
  font-weight: 500;
  font-size: 0.99vw;
  line-height: 1.8vw;
}

/*ServicesIII*/
.servicesIII{
  width: 100%;
  height: 47vw;
}
.servicesIIIContent{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
.servIIIDivIcon{
  /* margin-top: 3vw; */
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

/*FAQ*/
.FAQ{
  height: auto;
  width: 100%;
  color: white;
}
.FAQ .mainDivFAQ{
  width: 95%;
  height: auto;
  margin: 6vw auto 3vw;
  display: flex;
  justify-content: space-between;
}
.mainDivFAQ .FAQS{
  width: 65%;
  height: 60%;
}
.mainDivFAQ .FAQImage{
  width: 32%;
  height: 20%;
}
.FAQQues{
  display: flex;
}
.FAQQues div{
  width: 2.1vw;
  height: 2.1vw;
  margin-right: 1.4vw;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.2vw;
}
.FAQQues div p{
  line-height: 2.4vw;
  cursor: pointer;
  color: #000000;
}
.FAQQues h3{
  font-family: 'Lexend Deca';
  font-weight: 700;
  font-size: 1.7vw;
  line-height: 2.4vw;
}
.FAQAns{
  font-family: 'Space Grotesk';
  font-weight: 400;
  font-size: 1vw;
  line-height: 1.7vw;
  width: 88%;
  margin-left: 3.5vw;
}
.FAQAns li{
  margin-left: 1.3vw;
}
.FAQHr{
  border: 0.15vmax solid white;
  margin: 1.4vw 3.5vw;
}
.buttonFAQ{
  width: 9.5vw;
  height: 3.3vw;
  display: flex;
  justify-content: center;
  margin: 0 auto 2vw;
}
.buttonFAQ button{
  font-size: 0.9vw;
  border-radius: 0.6vw;
  padding: 0vw 2vw;
  background-color: #000000;
  color: #FFFFFF;
  width: 100%;
  height: 100%;
  font-family: 'Lexend Deca';
  font-weight: 700;
  text-align: center;
}






/*new css by intern*/
.services-container {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  padding: 20px 60px;
  background: #F7F7F7;
}

.cards-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); 
  gap: 20px 24px;
  max-width: 600px;
  margin: 0 auto;
  margin-bottom: 40px;
  
}

.card-mobile {
  position: relative;
  border-radius: 16px;
  height: 140px;
  width: 100%;
  aspect-ratio: 4/3;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  transition: transform 0.4s ease-in-out;
  border: 2px solid black; 
}

.card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.card-mobile h3 {
  color: white;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 2px;
}

.card-mobile p {
  color: white;
  font-size: 12px;
  margin: 0;
  opacity: 0.9;
}

.car-image-wrapper {
  margin-top: 10px;
}

.car-image {
  width: 108px;
  height: 37px;
  object-fit: contain;
}


@media screen and (max-width: 768px) {
  .services-container {
      padding: 20px 100px;
  }

  .cards-grid {
      gap: 30px 20px;
  }

  .card-mobile {
      height: 120px;
  }

  .card-mobile h3 {
      font-size: 16px;
  }

  .card-mobile p {
      font-size: 11px;
  }

  .car-image {
      width: 90px;
      height: 31px;
  }
}

@media screen and (max-width: 480px) {
  .services-container {
      padding: 40px;
      padding-bottom: 5px;
      padding-right: 30px;
  }

  .cards-grid {
      gap: 30px 20px;
  }

  .card-mobile {
      height: 113px;
      width: 150px;
  }

  .card-mobile h3 {
      font-size: 14px;
  }

  .card-mobile p {
      font-size: 10px;
  }

  .car-image {
      width: 107px;
      height: 35px;
  }
}
.card-mobile:hover {
  cursor: pointer; 
  transform: scale(1.05); 
}


/*mobile version brand css*/
/* CSS for the marquee rows and images */
 
  .mobile-version-headingII {
    font-family: Lexend Deca;
font-size: 18px;
font-weight: 500;
line-height: 21px;
text-align: center;

text-underline-position: from-font;
text-decoration-skip-ink: none;
padding: 10px;

  }
  

  .Mobile-brand-wrapper {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    

  }

  .mobile-brand-row {
    display: flex;
    gap: 20px;
    width: 100%;
    overflow: hidden;
    margin-bottom: 20px;
  }

  .brand-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f1f1f1;
    border-radius: 10px;
    padding: 10px;
    min-width: 60px; 
  }

  .brand-logo img {
    width: 100%;
    height: auto;
    max-width: 90px;
  }


/* find my car css */
.mobile-find-car-container {
  display: flex;
  
  justify-content: center;
  align-items: center;
  background-color: #000;
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  overflow: hidden;
  width: 90%;
  max-width: 390px;
  margin: 20px auto;
  padding-bottom: 5px;
}

.mobile-find-car-content {
  text-align: left;
  margin-bottom: 20px;
  padding-right: 30px;
}

.mobile-find-car-title {
  font-size: 15px;
  font-weight: 700;
  line-height: 21px;
  margin-bottom: 12px;
}

.mobile-find-car-description {
  font-size: 10px;
  margin-bottom: 20px;
  font-weight: 200;
  line-height: 14.2px;
}

.mobile-find-car-button {
  background-color: #fff;
  color: #000;
  border: none;
  padding: 10px 20px;
  font-size: 8px;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 600;
  line-height: 11.2px;
  
}

.mobile-card-row {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 10px;
  padding-bottom: 20px;
}

.mobile-card {
  position: relative;
  width: 87px;
  height: 53px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  
  opacity: 1;
}

.mobile-card-orange {
  background-color: #F65D34;
  transform: rotate(342deg);
  z-index: 1;
  top: 20px;
  border-radius: 10px;
}

.mobile-card-white {
  background-color: #fff;
  width: 91px;
  height: 53px;
  z-index: 2;
  margin: -10px 0;
  border-radius: 10px;
}

.mobile-card-green {
  background-color: #51DC99;
  transform: rotate(20deg);
  z-index: 0;
  border-radius: 10px;
  bottom: 12px;
}

.mobile-card-image {
  width: 78px;
  height: 27px;
}

.mobile-card-check-icon {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 10px;
  height: 10px;
}

/* Popup css */
.mobile-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.mobile-popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 90%;
  position: relative;
  z-index: 1001;
}

.mobile-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.mobile-popup-title {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  color: black;
}

.mobile-popup-subtitle {
  font-size: 16px;
  margin-bottom: 30px;
  text-align: center;
  color: black;
}
.mobile-popup-form {
  display: flex;
  flex-direction: column;
}

.mobile-form-group {
  margin-bottom: 15px;
}

.mobile-form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: black;
}

.mobile-form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.mobile-form-actions {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.mobile-enquire-btn {
  flex: 1;
  padding: 10px;
  background-color: #000;
  color: #fff;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.mobile-enquire-btn:hover {
  background-color: #333;
}

.mobile-close-btn-alt {
  flex: 1;
  padding: 10px;
  background-color: #ccc;
  color: #000;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.mobile-close-btn-alt:hover {
  background-color: #bbb;
}
.error-text {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}
.mobile-popup-content1 {
  background-color: #fff;
  padding: 60px 10px;
  max-width: -moz-fit-content;
  max-width: fit-content;
  width: 95%;
  height: 60%;
  position: relative;
  z-index: 1001;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* explore service css */
.mobile-find-car-container-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  overflow: hidden;
  width: 90%;
  max-width: 390px;
  margin: 20px auto;
  padding-bottom: 5px;
}

.mobile-find-car-content-1 {
  text-align: left;
  margin-bottom: 20px;
  padding-right: 30px;
}

.mobile-find-car-title-1 {
  font-size: 15px;
  font-weight: 700;
  line-height: 21px;
   margin-bottom: 10px; 
}

.mobile-find-car-description-1 {
  font-size: 10px;
  margin-bottom: 20px;
  font-weight: 200;
  line-height: 13px;
}

.mobile-find-car-button-1 {
  background-color: #fff;
  color: #000;
  border: none;
  padding: 10px 20px;
  font-size: 8px;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 600;
  line-height: 11.2px;
}

.mobile-card-row-1 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 10px;
  padding-bottom: 20px;
}

.mobile-card-1 {
  position: relative;
  width: 87px;
  height: 53px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  opacity: 1;
}

.mobile-card-orange-1 {
  background-color: #D3DCE8;
  height: 53px;
  transform: rotate(-0deg);
  z-index: 1;
  top: 21px;
  border-radius: 10px;
  width: 87px;
}

.mobile-card-white-1 {
  background-color: #fff;
  width: 102px;
  height: 53px;
  z-index: 2;
  margin: -10px 0;
  border-radius: 10px;
}

.mobile-card-green-1 {
  background-color: #5CA7FA;
  z-index: 0;
  border-radius: 10px;
  bottom: 16px;
  width: 87px;
  height: 53px;
}

.mobile-card-green-1 img {
  margin-top: 20px;
}

.mobile-card-image-1 {
  width: 78px;
  height: 27px;
}

.mobile-card-check-icon-1 {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 10px;
  height: 10px;
}

/* Master css */
.mobile-container-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  
  width: 100%;
}

.mobile-ad-container {
  position: relative;
  width: 350px;
  height: 98px;
}

.mobile-background-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px 0 0 0;
  overflow: hidden;
}

.mobile-background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mobile-content {
  position: absolute;
  top: 0;
  left: 0;
  padding: 12px;
  color: white;
}

.mobile-text-container {
  position: absolute;
  width: 128px;
  height: 28px;
  top: 17px;  /* Adjusted from 106px to fit within container */
  left: 24px;  /* Adjusted from 36px to account for content padding */
}

.mobile-heading {
  font-weight: 700;
  line-height: 14px;
  font-size: 10px;
}

.mobile-description-container {
  position: absolute;
  width: 108px;
  height: 17px;
  top: 49px;  /* Adjusted from 138px to fit within container */
  left: 25px;  /* Adjusted from 37px to account for content padding */
}

.mobile-description {
  font-size: 5px;
  line-height: 7px;
  font-weight: 400;
}

.mobile-enquire-btn1 {
  position: absolute;
  top: 70px;
  left: 25px;
  background-color: black;
  border: none;
  padding: 6px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 6px;
  line-height: 6px;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  color: white;
  
  /* width: 48px; */
}

.mobile-car-image {
  position: absolute;
  width: 91px;
  height: 73px;
  top: 24px;
  left: 170px;
  object-fit: contain;
  z-index: 2;
}

.mobile-salesman-image {
  position: absolute;
  width: 63px;
  height: 81px;
  top: 17px;
  left: 273px;
  object-fit: contain;
  z-index: 2;
}


/* Swiper CSS */
.swiper-parent{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  padding-bottom: 40px;
}

.swiper-container {
  width: 350px;
  height: 95px;
}

.swiper-slide {
  width: auto;
  height: auto;
}

.slide-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.swiper {
  padding-bottom: 12px !important; 
}

.swiper-pagination {
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 4px !important;
  bottom: 0 !important;  
  position: absolute !important;
  margin-top: 6px !important; 
}

/* Style individual bullets */
.swiper-pagination-bullet {
  width: 8px !important;
  height: 8px !important;
  background: #d9d9d9 !important;
  opacity: 1 !important;
  margin: 0 4px !important;
}

/* Style active bullet */
.swiper-pagination-bullet-active {
  background: black !important;
}

/* Experience the Difference css */
  
/* Parent Container */
.mobile11-swiper-parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  padding-bottom: 20px;
}

/* Heading Container */
.mobile11-heading-container {
  text-align: center;
  margin-bottom: 16px;
}

.mobile11-title {
  font-family: "Lexend Deca", sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 21px;
  text-align: center;
  color: black;
}

.mobile11-subtitle {
  font-family: "Lexend Deca", sans-serif;
  font-size: 8px;
  font-weight: 400;
  line-height: 11.2px;
  text-align: center;
  color: #666;
}

/* Swiper Container */
.mobile11-swiper-container {
  width: 350px;
  height: 95px;
  overflow: hidden; /* Prevent content overflow */
}

/* Card Styles */
.mobile11-card {
  background-color: #F7F7F7;
  border-radius: 10px;
  width: 150px;
  padding: 0 5px;
  height: 95px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mobile11-card-title {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 8px;
  color: black;
  line-height: 21px;
}

.mobile11-card-text {
  font-size: 8px;
  color: #555;
  font-weight: 400;
  line-height: 11.2px;
  text-align: center;
}
.super-container{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

/* Explore Car css */

.mobile-explore-main-container {
  padding: 20px;
  font-family: Arial, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
}


.mobile-explore-container {
  border-radius: 10px;
  overflow: hidden;
  width: 340px;
  /* height: 320px; */
  background-color: #f7f7f7;
  /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
}


.mobile-explore-heading-container {
  text-align: center;
  padding: 30px 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  padding-top: 10px;
  
}


.mobile-explore-main-slider-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px; 
}


.mobile-explore-car-image-container {
  position: absolute;
  top: -60px; 
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: center;
}

.mobile-explore-car-image-container img {
  width: auto;
  max-width: 200px; 
  height: auto;
}


.mobile-explore-car-details-container {
  background-color: #e0e0e0;
    border-radius: 10px;
    padding: 20px;
    padding-top: 55px;
    width: 315px;
    display: flex
;
    justify-content: space-between;
    text-align: left;
    height: 105px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
}


.car-details-left h3 {
  margin: 0;
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  margin-top: 10px;
}

.car-details-left p {
  margin: 5px 0;
  
  font-size: 12px;
  font-weight: 600;
  line-height: 16.8px;
  margin-top: 3px;
}


.car-details-right p {
  margin: 5px 0;
    
    font-size: 8px;
    line-height: 11.2px;
    margin-top: 13px;
    font-weight: 300;
}

.car-details-right .emi {
  color: #f44336;
    font-weight: bold;
    margin-top: 2px;
}

/* Explore button styling */
.mobile-explore-button {
  display: block;
  width: 100%;
  height: 44px;
  background-color: black;
  color: white;
  font-weight: 700;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  font-size: 15px;
  line-height: 21px;
  
}
.super-h2{
  
  padding-top: 10px;
    padding-bottom: 20px;
}


/* New Navbar */
.MenuDropdown {
  position: fixed;
  top: 0;
  left: -100%; 
  height: 100%;
  width: 70%;
  background-color: white;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); 
  transition: left 0.5s ease-in-out; 
  z-index: 1000;
}

.MenuDropdown.MenuShow {
  left: 0; 
}

.menuIconNav {
  cursor: pointer;
  color: #596780;
}



/* testimonial css */

.mobile-test-main-container {
  
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 10px;
 
}

.mobile-test-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 1rem;
  line-height: 21px;
  color: black;
}

.mobile-test-card-container {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  
}

.mobile-testimonial-card {
  height: 190px;
  min-width: 340px;
  max-width: 390px;
  background-color: #000;
  color: #fff;
  border-radius: 10px;
  padding: 1.5rem;
  padding-bottom: 1.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; /* Keeps card in place */
}

.mobile-testimonial-content {
  display: flex;
  
  justify-content: space-between;
  align-items: flex-start;
  width: 100%; /* Ensures proper layout within the card */
}

.mobile-testimonial-title {
  font-size: 10px;
  font-weight: 700;
  margin-bottom: 10px;
  line-height: 14px;
}

.mobile-testimonial-text {
  font-size: 8px;
  font-weight: 400;
  line-height: 11.2px;
  margin-bottom: 1rem;
}

.mobile-testimonial-author {
  font-size: 5px;
  text-align: right;
  font-weight: 400;
  line-height: 7px;
  margin-bottom: 10px;
}

.mobile-testimonial-author span {
  font-size: 5px;
  font-weight: 400;
  line-height: 7px;
}

.mobile-testimonial-image-container {
  width: 102px; 
  height: 126px;
  background-color: #fff; 
  border-radius: 10px;
  
  overflow: hidden; 
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.mobile-testimonial-image {
  width: 100%; 
  height: 100%; 
  object-fit: cover; 
  border-radius: inherit; 
}


/* Swiper Pagination Styling */
.swiper-pagination-bullet {
  background: #fff;
  opacity: 0.8;
}

.mobile-test-main-container .swiper-pagination-bullet-active {
  background: #ff5722 !important;
}

.mobile-testimonial-content-container{
  display: flex;
  flex-direction: column;
  width: 60%;
  margin-top: 1rem;
  
  
}
.swiper-pagination {
  width: 100% !important;
  display: flex !important
;
  justify-content: center !important;
  align-items: center !important;
  gap: 4px !important;
  bottom: 0 !important;
  position: absolute !important;
  margin-top: 6px !important;
  margin-bottom: 3px !important;
}


/* Mobile Footer */

/* Container */
.mobile-footer-container {
  background-color: #000;
  color: #fff;
  padding: 20px;
  padding-bottom: 5px;
  font-family: 'Space Grotesk', sans-serif;
}

/* Logo Section */
.mobile-footer-logo-container {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.mobile-footer-logo {
  width: 80px;
  height: 29px;
}

.mobile-footer-tagline {
  margin-left: 15px;
  font-size: 14px;
  color: #999;
  font-family: 'Space Grotesk', sans-serif;
}

/* Links Section */
.mobile-footer-links-container {
  margin-bottom: 20px;
  font-family: 'Space Grotesk', sans-serif;
}

.mobile-footer-link {
  display: flex
;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    color: #fff;
    margin: 10px 0;
    cursor: pointer;
    font-weight: 300;
    line-height: 10px;
    font-family: 'Space Grotesk', sans-serif;
    color: #E5E6E8;
}

/* Rotating the dropdown icon */
.mobile-footer-dropdown-icon {
  width: 16px;
  margin-left: 10px;
  transition: transform 0.3s ease;
}

.mobile-footer-dropdown-icon.rotated {
  transform: rotate(180deg);
}


.mobile-footer-dropdown-content {
  margin-left: 20px;
  font-size: 10px;
  color: #E5E6E8;
  padding: 10px 0;
  font-family: 'Space Grotesk', sans-serif;
}

/* Social Media Icons */
.mobile-footer-social-icons {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.mobile-footer-icon {
  width: 20px;
  height: 20px;
}

/* App Store Badges */
.mobile-footer-store-container {
  margin-bottom: 15px;
}

.mobile-footer-store-heading {
  font-size: 12px;
    margin-bottom: 10px;
    font-weight: 500;
    font-family: 'Space Grotesk', sans-serif;
    line-height: 10px
}

.mobile-footer-store-icons {
  display: flex;
  gap: 10px;
}

.mobile-footer-store-badge {
  width: 80px;
}

/* Copyright */
.mobile-footer-copyright {
  font-size: 8px;
    color: #777;
    font-family: 'Space Grotesk', sans-serif;
    text-align: center;
    /* padding-top: 4px; */
    line-height: 22px;
    font-weight: 300;
}
/* Adjusted Dropdown Content */

.mobile-footer-dropdown-content {
  margin-left: 0; 
  padding-left: 20px; 
  font-size: 10px; 
  line-height: 18px; 
  font-weight: 300; 
  color: #E5E6E8; 
  padding: 10px 0;
  padding-top: 0; 
  font-family: 'Space Grotesk', sans-serif;
}


/* mobile blog  */


/* Container styling */
.mobile-blog-container {
  padding: 20px;
  background: #fff;
}

.mobile-blog-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
}

/* Card styling */
.mobile-blog-card {
  background: black;
  border-radius: 10px;
  overflow: hidden;
  color: white;
  margin-bottom: 20px;
}

.mobile-blog-card-image {
  width: 100%;
  height: 112px;
  object-fit: cover;
}

.mobile-blog-card-content {
  padding: 10px;
  padding-top: 4px;
}

.mobile-blog-card-meta {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
  gap: 5px;
  margin-bottom: 10px;
}

.mobile-blog-author-section {
  display: flex;
  align-items: center;
  gap: 5px;
}

.mobile-blog-author-image {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  object-fit: cover;
}

.mobile-blog-author-name {
  font-size: 9px;
    font-weight: 400;
    line-height: 9.8px;
}

.mobile-blog-date-section {
  display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
  }


.mobile-blog-date {
  font-size: 10px;
  color: white;
}

.mobile-blog-shares {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 10px;
  color: white;
}

.mobile-blog-share-icon {
  width: 12px;
  height: 12px;
  color: white;
}

.mobile-blog-card-title {
  font-size: 8px;
    font-weight: 800;
    margin-bottom: 3px;
    line-height: 11.2px;
    text-align: left;
}

.mobile-blog-card-description {
  font-size: 8px;
    font-weight: 400;
    margin-bottom: 15px;
    line-height: 10px;
    color: white
}

.mobile-blog-card-link {
  color: white;
  font-size: 10px;
  text-decoration: underline;
  cursor: pointer;
}

/* Button styling */
.mobile-blog-button {
  display: block;
    /* margin: 20px auto; */
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 800;
    background: #000;
    color: #fff;
    border: none;
    width: 100%;
    border-radius: 10px;
    cursor: pointer;
    line-height: 21px;
}




/* load blog page */

.load-mobile-container {
  padding: 20px;
  margin-bottom: 15px;
  background: #fff;
  border-radius: 10px; 
  box-shadow: 0px 2px 2px 0px #00000040; 
}

/* Blog Grid Layout */
.load-mobile-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); 
  gap: 20px; 
  margin-bottom: 20px;
}

.load-mobile-card-wrapper {
  width: 100%;
}

/* Blog Card Styling */
.load-mobile-card {
  background: black;
  border-radius: 10px;
  overflow: hidden;
  color: white;
}

.load-mobile-card-image {
  width: 100%;
  height: 112px;
  object-fit: cover;
}

.load-mobile-card-content {
  padding: 10px;
  padding-top: 4px;
}

.load-mobile-card-meta {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  margin-bottom: 10px;
}

.load-mobile-author-section {
  display: flex;
  align-items: center;
  gap: 5px;
}

.load-mobile-author-image {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  object-fit: cover;
}

.load-mobile-author-name {
  font-size: 9px;
  font-weight: 400;
  line-height: 9.8px;
}

.load-mobile-date-section {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.load-mobile-date {
  font-size: 10px;
  color: white;
}

.load-mobile-shares {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 10px;
  color: white;
}

.load-mobile-share-icon {
  width: 12px;
  height: 12px;
  color: white;
}

.load-mobile-card-title {
  font-size: 8px;
  font-weight: 800;
  margin-bottom: 3px;
  line-height: 11.2px;
  text-align: left;
}

.load-mobile-card-description {
  font-size: 8px;
  font-weight: 400;
  margin-bottom: 15px;
  line-height: 10px;
  color: white;
}

.load-mobile-card-link {
  color: white;
  font-size: 10px;
  text-decoration: underline;
  cursor: pointer;
}

/* Load More Button */
.load-mobile-load-more {
  display: block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 800;
  background: #000;
  color: #fff;
  border: none;
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
  line-height: 21px;
  text-align: center;
}


/* Detailed blog */

/* Main Container */
.mobile-detail-blog-container {
  width: 100vw; 
  margin: 0; 
  padding: 0; 
  background: #fff;
  box-shadow: 0px 2px 2px 0px #00000040; 
  
  
  
}
.mobile-detail-blog-container {
  font-family: 'Lexend Deca', sans-serif;
}

/* Each Section Container */
.mobile-detail-blog-section {
  width: 100%; 
  padding: 15px; 
  margin-bottom: 20px; 
  background: #fff;
  box-shadow: 0px 2px 2px 0px #00000040;
  border-radius: 15px; 
}


/* Header Section */
.mobile-detail-blog-header h2 {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
}

.mobile-detail-blog-input {
  width: 100%;
  padding: 8px;
  font-size: 12px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.mobile-detail-blog-button {
  width: 100%;
  padding: 10px;
  background: #000;
  color: #fff;
  font-weight: 700;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  box-shadow: 0px 2px 2px 0px #00000040;
}

/* Explore Blogs Section */
.mobile-detail-blog-heading {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 15px;
}

.mobile-detail-blog-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.mobile-detail-blog-card {
  background: black;
  border-radius: 10px;
  color: white;
  overflow: hidden;
  box-shadow: 0px 2px 2px 0px #00000040;
}

.mobile-detail-blog-image {
  width: 100%;
  height: 120px;
  object-fit: cover;
}

.mobile-detail-blog-content {
  padding: 10px;
}

.mobile-detail-blog-title {
  font-size: 10px;
  font-weight: 700;
  margin-bottom: 8px;
}

.mobile-detail-blog-meta {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 8px;
}

.mobile-detail-blog-author {
  display: flex;
  align-items: center;
  gap: 5px;
}

.mobile-detail-blog-author-img {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  object-fit: cover;
}

.mobile-detail-blog-author-name {
  font-size: 10px;
}

.mobile-detail-blog-date {
  display: flex;
  justify-content: space-between;
  font-size: 9px;
}

.mobile-detail-blog-share {
  display: flex;
  align-items: center;
  gap: 3px;
}

.mobile-detail-blog-share-icon {
  width: 12px;
  height: 12px;
}

.mobile-detail-blog-description {
  font-size: 9px;
  margin-bottom: 10px;
}

.mobile-detail-blog-link {
  font-size: 10px;
  text-decoration: underline;
  color: white;
  cursor: pointer;
}
/* new css */
/* Blog Showcase Section */
.mobile-blog-details-showcase {
  padding: 20px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  margin-bottom: 30px;
  
}

/* Blog Image */
.mobile-blog-detail-image {
  width: calc(100% + 40px);
  margin-left: -20px;
  margin-right: -20px;
  object-fit: cover;
  display: block;
  margin-bottom: 10px;
  
}

/* Blog Title */
.mobile-blog-detail-title {
  font-size: 18px;
    font-weight: 700;
    color: #333;
    margin-bottom: 5px;
    line-height: 21px;
}


/* Author Details Section */
.mobile-blog-detail-author-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

/* Author Image */
.mobile-blog-detail-author-image {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  object-fit: cover;
}

/* Author Info */
.mobile-blog-detail-author-info {
  flex-grow: 1;
  
}

.mobile-blog-detail-author-name {
  font-size: 10px;
  font-weight: 500;
  line-height: 9.8px;
}

.mobile-blog-detail-publish-details {
  font-size: 8px;
    color: black;
    font-weight: 400;
    margin-bottom: 10px;
}

/* Blog Stats */
.mobile-blog-detail-stats {
  display: flex;
  align-items: center;
  gap: 5px;
}

.mobile-blog-detail-icon {
  width: 14px;
  height: 14px;
}

/* Blog Description */
.mobile-blog-detail-description {
  font-size: 9px;
  color: black;
  line-height: 11.2px;
  font-weight: 500;
}


/* New Design Section */
.mobile-blog-details-design {
  margin-top: 20px;
  
  border-radius: 10px;
  
  
}

.mobile-blog-details-image {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 15px;
  object-fit: cover;
}

.mobile-blog-details-tabs {
  display: flex;
  gap: 20px;
  margin-bottom: 15px;
}

.mobile-blog-details-tab {
  padding: 12px 20px;
  font-size: 15px;
  font-weight: 600;
  border: none;
  background: #000;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
  width: 50%;
}

.mobile-blog-details-info {
  font-size: 14px;
  color: #444;
  line-height: 1.6;
}
/* Price Section */
.mobile-blog-details-price-section {
  margin-top: 20px;
  background: #ffffff;
  border-radius: 10px;
  
  
  
}

.mobile-blog-details-price-image {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 15px;
  object-fit: cover;
}

.mobile-blog-details-price-button {
  background-color: #000;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  padding: 12px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 15px;
  width: 100%;
}

.mobile-blog-details-price-info {
  font-size: 14px;
  color: #444;
  line-height: 1.6;
}
.mobile-blog-detail-follow-button{
  background-color: #00ACED;
    color: white;
    font-weight: 400;
    padding: 3px;
    border-radius: 5px;
    font-size: 6.2px;
    padding-left: 8px;
    padding-right: 8px;
    text-align: center;
}
.mobile-blog-detail-stat{
  display: flex;
  gap:4px
}
.mobile-blog-detail-stat-author{
  font-size: 10px;
}

.mobile-blog-details-tabs1{
display: flex;
justify-content: center;
}

/* new car css */

/* General styling */
.mobile-new-car-container {
  margin-top: -10px;
  padding-top: 0;
  
}

.mobile-new-car-section {
  text-align: center;
  box-shadow: 0px 2px 2px 0px #00000040; 
  background-color: #fff; 
  border-radius: 10px; 
  padding: 10px; 
  border: 0.2px solid #808182;
}


/* Title styling */
.mobile-new-car-title {
  font-size: 15px;
  margin-bottom: 10px;
  font-weight: 600;
  line-height: 21px;
}

/* Tabs (SUV, Hatchback, etc.) styling */
.mobile-new-car-tabs {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 15px;
  border-bottom: 0.2px solid #4E5A65; /* Full underline below all buttons */
  padding: 0 60px;
  position: relative; /* Needed for positioning */
}

.mobile-new-car-tab {
  padding: 5px 10px;
  font-size: 10px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  position: relative; /* Needed for underline positioning */
  transition: color 0.3s ease;
  font-weight: 400;
  line-height: 11.2px;
  margin-bottom: 3px
}

.mobile-new-car-tab::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 1px; /* Place the active underline above the section underline */
  width: 0;
  height: 2px;
  background-color: #000; /* Active underline color */
  transition: width 0.3s ease;
}

.mobile-new-car-tab.active::after {
  width: 100%; /* Show underline for the active tab */
}

.mobile-new-car-tab:hover {
  color: #333; /* Optional hover color for better UX */
}

/* Swiper styling */
.mobile-new-car-swiper {
  padding: 10px 0;
}

.mobile-new-car-slide {
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  width: auto;
}

/* Card styling */
.mobile-new-car-card {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding-bottom: 15px;
}

.mobile-new-car-card-image {
  position: relative;
  width: 100%;
  height: 100px;
  overflow: hidden;
  
}

.mobile-new-car-card-bg {
  width: 100%;
  height: 100%;
  object-fit: fill;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0;
}

.mobile-new-car-card-car {
  position: absolute;
  width: 75px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 60px;
}

.mobile-new-car-card-name {
  font-size: 8px;
    margin: 5px 0;
    font-weight: 400;
    line-height: 11.2px;
    text-align: left;
    padding-left: 10px;
}


.mobile-new-car-card-price {
  font-size: 8px;
    margin: 5px 0;
    font-weight: 400;
    line-height: 11.2px;
    text-align: left;
    padding-left: 10px;
    margin-top: -4px;
}

.mobile-new-car-card-btn {
  margin-top: 5px;
    padding: 5px 10px;
    background-color: white;
    color: black;
    border: none;
    border-radius: 5px;
    font-size: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    border: 0.5px solid;
    width: 85%;
    font-weight: 400;
}



.mobile-new-car-view-all {
  display: flex;
  justify-content:start;
  align-items: center;
  margin-top: 1px;
  text-align: left;
  padding-left: 20px;
}

.mobile-new-car-view-all button {
  background: none;
    color: #000;
    border: none;
    font-size: 8px;
    
    cursor: pointer;
    transition: color 0.3s ease;
    font-weight: 700;
    line-height: 11.2px;
    display: flex;
    align-items: center;
    gap: 2px;
}


.mobile-new-car-slide:first-child {
  margin-left: 20px; 
}

/* brand car  */

.mobile-new-car-brand-section{
  
   
    box-shadow: 0px 2px 2px 0px #00000040; 
    border: 0.2px solid #808182; 
    background-color: #fff; 
    border-radius: 10px; 
    padding: 10px; 
    margin-top: 15px;
    margin-bottom: 15px;
  
}
.mobile-new-car-tabs-price{
  display: flex;
    justify-content: space-evenly;
    margin-bottom: 15px;
    border-bottom: 0.2px solid #4E5A65;
    position: relative
}

/* expert section */
.expert-advice-image-container {
  position: relative;
  display: inline-block;
}

.expert-advice-full-image {
  width: 100%;
  height: auto;
  display: block;
}

.expert-advice-button-bottom-left {
  position: absolute;
  bottom: 35px;
  left: 17px;
  padding: 3px 10px;
  background-color: black;
  color: #fff;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 10px;
  line-height: 14px;
  font-weight: 600;
}


/*Careers*/
.careers{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.careers .careersJobs{
  width: 93%;
  height: auto;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: auto 2.5vw 3.7vw;
}
.careersJobs .JobsBox{
  width: 95%;
  height: 15.6vw;
  display: flex;
  justify-content: space-between;
  margin-bottom: 4vw;
}
.JobsBox .JobsBoxSub{
  height: auto;
  width: 31.2%;
  border: 0.1vw solid #000000;
  border-radius: 0.7vw;
  font-family: 'Lexend Deca';
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 1.5vw;
}
.JobsBoxSub h4{
  font-size: 1.4vw;
  margin-bottom: 1vw;
}
.JobsBoxSub .p_jobsBoxSub{
  font-family: 'Space Grotesk';
  font-weight: 400;
  font-size: 0.9vw;
  line-height: 1.5vw;
  margin-bottom: 1.5vw;
}
.JobsBoxSub .JobsBoxBtns{
  display: flex;
  justify-content: space-between;
  gap: 1vw;
}
.JobsBoxSub .JobsBoxBtns button{
  font-size: 1.17vw;
  line-height: 1.17vw;
  font-weight: 500;
  border: 0.1vw solid #FF3F00;
  width: 9vw;
  height: 2.2vw;
  /* align-items: center; */
}
.JobsBoxBtns button:nth-child(1){
  background-color: #FF3F00;
  color: #FFFFFF;
}
.JobsBoxBtns button:nth-child(2){
  background-color: #FFFFFF;
  color: #FF3F00;
  border-color: #FF3F00;
}

/*prereq_popup*/
.prereq_popup{
  background-color: white;
  border-radius: 0.9vw;
  height: auto;
  width: 32vw;
  padding: 1.3vw;
  box-shadow: 0vw 0.7vw 1.2vw rgba(0, 0, 0, 0.2);
}

.prereq_popup h4 {
  font-size: 1.35vw;
  margin-bottom: 1.1vw;
  font-family: 'Lexend Deca';
}

.prereq_popup hr {
  border: none;
  border-top: 0.12vw solid #000;
  margin: 0 0 1.1vw;
}

.prereq_popup p {
  font-size: 1.1vw;
  margin-bottom: 0.7vw;
}

.prerequisites-list {
  list-style-type: disc;
  padding-left: 1.5vw;
  margin-bottom: 1.2vw;
}

.prerequisites-list li {
  font-size: 1.04vw;
  line-height: 1.5vw;
  margin-bottom: 0.7vw;
}

.details-box {
  border: 0.1vw solid #000000;
  border-radius: 0.6vw;
  padding: 1vw;
  margin-top: 0.7vw;
  font-family: 'Space Grotesk';
}

.details-row {
  display: flex;
  justify-content: space-between;
  font-size: 0.95vw;
  margin-bottom: 0.7vw;
  font-family: 'Space Grotesk';
}

.details-row div {
  width: 50%;
}

/* Add this in your CSS file */
.blurred {
  filter: blur(5px);
  pointer-events: none; /* Disable interactions with blurred section */
}

.PRpopup {
  display: grid;
  place-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.prereq_popup {
  background-color: white;
  padding: 1.4vw;
  border-radius: 1.2vw;
  width: 50vw;
  max-width: 33vw;
  position: relative;
}

.Jobapply_popup .Jobapply_popup_form input::placeholder{
  font-size: 1vw;
  line-height: 1vw;
}

